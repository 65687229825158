.loader{
      height: 50%; /* Set your own height: percents, ems, whatever! */
      width: 50%; /* Set your own width: percents, ems, whatever! */
      overflow: auto; /* Recommended in case content is larger than the container */
      margin: auto; /* Center the item vertically & horizontally */
      position: absolute; /* Break it out of the regular flow */
      top: 0; left: 0; bottom: 0; right: 0; /* Set the bounds in which to center it, relative to its parent/container */
  }

  

.gifs{
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gif{
    padding: 0 2px;
}